.container {
    margin-bottom: 20px;
}

.container select:disabled {
    opacity: 0.5;
    pointer-events: none;
}

.calendarInputContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    width: 323px;
    height: 50px;
    left: 148px;
    top: 646px;
    background: #ffffff;
    border: 1px solid #e3e4e7;
    padding-left: 6px;
    padding-right: 3px;
}

.calendarContainerLTR {
    width: 323px;
    position: absolute;
    left: 0;
    z-index: 9;
}

.calendarContainerRTL {
    width: 323px;
    position: absolute;
    right: 0;
    z-index: 9;
}

.calendarContainerHided {
    display: none;
}

.numberColors {
    color: var(--main-color);
}

.calendarIcon {
    width: 16px;
    height: 16px;
}

.calendarContainerLabelLTR {
    margin-top: 6px;
    margin-left: 10px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1px;
    color: #62698c;
}

.calendarContainerLabelRTL {
    margin-top: 6px;
    margin-right: 10px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1px;
    color: #62698c;
}

.calendarContainerIconLTR {
    margin-top: 14px;
    margin-right: 10px;
}

.calendarContainerIconRTL {
    margin-top: 14px;
    margin-left: 10px;
}

.enabled {
    color: green;
}

@media screen and (max-width: 950px) and (min-width: 520px) {
    .calendarContainerLabel {
        margin-top: 6px;
        margin-left: 10px;
        font-family: var(--font-family);
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 1px;
        color: #62698c;
    }
}

@media screen and (max-width: 520px) {
    .calendarInputContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        box-sizing: border-box;
        width: 100%;
        height: 50px;
        background: #ffffff;
        border: 1px solid #e3e4e7;
        padding-left: 6px;
        padding-right: 3px;
    }

    .calendarContainerLTR {
        width: 90%;
        position: absolute;
        z-index: 9;
        display: flex;
        align-items: center;
        margin-left: 5%;
    }

    .calendarContainerRTL {
        width: 90%;
        position: absolute;
        z-index: 9;
        display: flex;
        align-items: center;
        margin-right: 5%;
    }

    .calendarContainerLabel {
        margin-top: 6px;
        margin-left: 10px;
        font-family: var(--font-family);
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 1px;
        color: #62698c;
    }
}

