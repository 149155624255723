.container {
    height: 166px;
    border-bottom: 1px solid #e3e4e7;
}

.appointmentDateLabel {
    padding-top: 20px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--main-color);
}

.appointmentDateLabelPast {
    padding-top: 20px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--main-color);
}

.locationLabel {
    padding-top: 20px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 2px;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: var(--main-color);
}

.cancelAppointmentContainerLTR {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 20px;
}

.cancelAppointmentContainerRTL {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 20px;
}

.crossIconLTR {
    width: 14px;
    height: 14px;
    margin-right: 10px;
    cursor: pointer;
}

.crossIconRTL {
    width: 14px;
    height: 14px;
    margin-left: 10px;
    cursor: pointer;
}

.crossIconLabel {
    border-bottom: 0.1px solid #747886;
    color: #747886;
    font-size: 11px;
    font-family: var(--font-family);
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 2px;
    cursor: pointer;
}

.additionalInformation {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 2px;
    color: var(--main-color);
    text-transform: uppercase;
}

.additionalInformationPast {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 2px;
    color: var(--main-color);
    text-transform: uppercase;
    margin-top: 20px;
}

.allInfosContainerLTR {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px;
    margin-top: 20px;
}

.allInfosContainerRTL {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 20px;
    margin-top: 20px;
}

.allInfosContainerPastLTR {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px;
    margin-top: 10px;
}

.allInfosContainerPastRTL {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 20px;
    margin-top: 10px;
}

.showLabel {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 2px;
    color: var(--main-color);
    margin-top: 20px;
}

.showLabelRed {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 2px;
    color: #a7022e;
    margin-top: 20px;
}

