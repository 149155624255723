.container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    max-width: 666px;
}

.infoTitle {
    color: var(--main-color);
    font-family: var(--font-family);
    letter-spacing: 2px;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 10px;
}

.containerInfo {
    display: flex;
    justify-content: flex-start;
    margin-left: 50px;
}

.containerInfoNoMargin {
    display: flex;
    justify-content: flex-start;
    margin-left: 0px;
    max-width: 666px;
}

.infoValue {
    color: var(--main-color);
    font-family: var(--font-family);
    letter-spacing: 2px;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    text-align: start;
}

@media screen and (max-width: 520px) {
    .container {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        max-width: 100%;
    }

    .containerInfo {
        display: flex;
        justify-content: flex-start;
        margin-left: 4px;
    }

    .containerInfoNoMargin {
        display: flex;
        justify-content: flex-start;
    }
}

