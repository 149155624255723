.container {
    height: 54px;
    text-align: center;
    background: #0c1b54;
    margin-bottom: 35px;
    margin-top: 35px;
    cursor: pointer;
}

.containerDisable {
    height: 54px;
    text-align: center;
    background: #b7bbca;
    margin-bottom: 35px;
    margin-top: 35px;
    cursor: default;
}

.medium {
    width: 335px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.large {
    width: 666px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.titleLabel {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 2px;
    color: white;
}

@media screen and (max-width: 520px) {
    .container {
        height: 54px;
        text-align: center;
        background: #0c1b54;
        margin-bottom: 35px;
        margin-top: 0px;
        cursor: pointer;
    }

    .medium {
        width: 90%;
    }

    .large {
        width: 100%;
    }

    .titleLabel {
        font-family: var(--font-family);
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 2px;
        color: white;
    }
}

