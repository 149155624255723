.containerRTL {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F6F6F6;
    flex-grow: 1;
    direction: rtl;
  }

  .containerLTR {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F6F6F6;
    flex-grow: 1;
    direction: ltr;
  }

.bodyContent{
  display: flex;
  flex-direction: column;
  align-items: center;
}