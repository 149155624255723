.container {
    width: 100%;
    margin-bottom: 20px;
}

.inputStyleLTR {
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    color: #62698c;
    font-family: var(--font-family);
    background: #ffffff;
    border: 1px solid #e3e4e7;
    padding-left: 15px;
}

.inputStyleRTL {
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    color: #62698c;
    font-family: var(--font-family);
    background: #ffffff;
    border: 1px solid #e3e4e7;
    padding-right: 15px;
}

.inputStyleErrorLTR {
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    color: #62698c;
    font-family: var(--font-family);
    background: #ffffff;
    border: 1px solid #d92926;
    padding-left: 15px;
}

.inputStyleErrorRTL {
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    color: #62698c;
    font-family: var(--font-family);
    background: #ffffff;
    border: 1px solid #d92926;
    padding-right: 15px;
}

@media screen and (max-width: 520px) {
    .container {
        width: 90%;
        margin-bottom: 20px;
    }

    .inputStyleLTR {
        box-sizing: border-box;
        width: 100%;
        height: 50px;
        font-size: 16px;
        color: #62698c;
        font-family: var(--font-family);
        background: #ffffff;
        border: 1px solid #e3e4e7;
        padding-left: 15px;
    }

    .inputStyleRTL {
        box-sizing: border-box;
        width: 100%;
        height: 50px;
        font-size: 16px;
        color: #62698c;
        font-family: var(--font-family);
        background: #ffffff;
        border: 1px solid #e3e4e7;
        padding-right: 15px;
    }

    .inputStyleErrorLTR {
        box-sizing: border-box;
        width: 100%;
        height: 50px;
        font-size: 16px;
        color: #62698c;
        font-family: var(--font-family);
        background: #ffffff;
        border: 1px solid #d92926;
        padding-left: 15px;
    }

    .inputStyleErrorRTL {
        box-sizing: border-box;
        width: 100%;
        height: 50px;
        font-size: 16px;
        color: #62698c;
        font-family: var(--font-family);
        background: #ffffff;
        border: 1px solid #d92926;
        padding-right: 15px;
    }
}

