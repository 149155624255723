.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.containerInfosDetails {
    width: 666px;
    margin-bottom: 50px;
    margin-top: 20px;
}

.containerInfosDetailsUpdate {
    width: 666px;
    margin-top: 20px;
}

.containerInfosDetailsTitle {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
}

.containerInfosDetailsTitleLabel {
    color: var(--main-color);
    font-family: var(--font-family);
    letter-spacing: 2px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
}

.noDataTitleLabel {
    color: var(--main-color);
    font-family: var(--font-family);
    letter-spacing: 2px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    margin-top: 50px;
}

.containerInfosDetailsTitleLine {
    border-bottom: 1px solid #e3e4e7;
    width: 566px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    margin-bottom: 20px;
}

.titleLabelContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    margin-bottom: 20px;
    height: 100px;
    border-bottom: 1px solid #e3e4e7;
    min-width: 666px;
}

.titleLabel {
    color: #0e1d51;
    font-family: var(--font-family);
    font-size: 16px;
    letter-spacing: 2px;
    font-style: normal;
    font-weight: normal;
}

.titleLabelSectionOptional {
    color: #0e1d51;
    font-family: var(--font-family);
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 2px;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 15px;
}

.headerUpdate {
    color: #0e1d51;
    font-family: var(--font-family);
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 2px;
    font-style: normal;
    font-weight: normal;
    margin-top: 64px;
}

@media screen and (max-width: 520px) {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90%;
    }

    .containerInfosDetails {
        width: 100%;
        margin-bottom: 50px;
        margin-top: 20px;
    }

    .containerInfosDetailsTitle {
        display: flex;
        justify-content: flex-start;
        margin-left: 0px;
        margin-top: 20px;
    }

    .containerInfosDetailsTitleLine {
        border-bottom: 1px solid #e3e4e7;
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        align-items: center;
        margin-left: 0px;
        margin-bottom: 20px;
    }
}

