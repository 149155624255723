.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.containerHidden {
    display: none;
}

.iconErrorLTR {
    width: 14px;
    height: 14px;
}

.iconErrorRTL {
    width: 14px;
    height: 14px;
    margin-left: 5px;
}

.titleErrorLTR {
    color: var(--error-color);
    font-family: var(--font-family);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;
    margin-left: 5px;
}

.titleErrorRTL {
    color: var(--error-color);
    font-family: var(--font-family);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;
    margin-right: 5px;
}

.titleErrorHalfInputLTR {
    color: var(--error-color);
    font-family: var(--font-family);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;
    margin-left: 5px;
    text-align: left;
}

.titleErrorHalfInputRTL {
    color: var(--error-color);
    font-family: var(--font-family);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;
    margin-left: 5px;
    text-align: right;
}

