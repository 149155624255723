.container {
    display: flex;
    align-items: flex-start;
}

.titleSectionLabel {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 2px;
    color: var(--main-color);
}

