.footerContentRTL {
    width: 100%;
    background-color: #0f1a48;
    direction: rtl;
}

.footerContentLTR {
    width: 100%;
    background-color: #0f1a48;
    direction: ltr;
}

.footerSocialnetwork {
    width: 100%;
    background-color: #101e46;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 0 60px;
}

.socialNetworkTitle {
    font-family: 'Baskerville';
    color: #a48156;
    font-size: 17px;
}

.footerLink {
    width: 65%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.footerLinkContainer {
    width: 100%;
    height: 117px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.footerExternalLinkContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    background-color: #0e1737;
    padding-bottom: 22px;
}

.footerExternalLinkContainer ul {
    list-style-type: none;
    padding: 0;
}

.footerExternalLink {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 30px;
    margin-right: 50px;
    margin-bottom: 22px;
}

.footerExternalLink a {
    text-decoration: none;
    color: white;
}

.footerExternalLinkTitle {
    color: white;
    letter-spacing: 1px;
    font-size: 10px;
    font-family: var(--font-family);
}

.footerLanguages {
    margin-top: 20px;
    margin-right: 9%;
    display: flex;
    flex-direction: row;
}

.footerLanguagesRTL {
    margin-top: 20px;
    margin-left: 9%;
    display: flex;
    flex-direction: row;
}

.footerLanguages select {
    border: 1px solid transparent;
    border-radius: 0;
    background-color: #0e1737;
    color: white;
    text-transform: uppercase;
    font-size: 10px;
    color: #fff;
    width: auto;
    min-width: 105px;
    padding-right: 20px;
    letter-spacing: 1px;
    font-family: var(--font-family);
}

.footerLanguagesRTL select {
    border: 1px solid transparent;
    border-radius: 0;
    background-color: #0e1737;
    color: white;
    text-transform: uppercase;
    font-size: 10px;
    color: #fff;
    width: auto;
    min-width: 105px;
    padding-right: 20px;
    letter-spacing: 1px;
    font-family: var(--font-family);
}

.footerHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}

.newsletterBlockTitle {
    font-family: 'Baskerville';
    color: #a48156;
    font-size: 17px;
}

.newsletterBlockDescription {
    font-family: var(--font-family);
    color: white;
    font-size: 15px;
    letter-spacing: 1.5px;
    width: 135px;
    text-transform: uppercase;
}

.contactUsBlockTitle {
    font-family: 'Baskerville';
    color: white;
    font-size: 17px;
    opacity: 0.5;
}

.contactUsBlockDescription {
    font-family: var(--font-family);
    color: white;
    font-size: 15px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.contactUsBlockLogo {
    margin-right: 27px;
}

.contactUsBlockLogoRTL {
    margin-left: 27px;
}

.subscribeBlock {
    background-color: white;
    width: 184px;
    height: 54px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    font-size: 12px;
    letter-spacing: 2px;
}

.subscribeBlock a {
    font-family: var(--font-family);
    text-decoration: none;
    color: #0e1737;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 1px;
}

.contactUsBlock {
    display: flex;
    align-items: center;
}

.contactUsBlockIn {
    display: flex;
    align-items: center;
}

.contactUsBlock a {
    text-decoration: none;
}

.findBoutiqueBlock {
    display: flex;
    align-items: center;
}

.findBoutiqueBlock a {
    text-decoration: none;
}

.findBoutiqueBlockIn {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
}

.ServiceBlockContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 200px;
}

.ServiceBlockContainer ul li a {
    font-family: var(--font-family);
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    vertical-align: top;
    opacity: 0.5;
}

.ServiceBloc {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.ServiceBlocTitle {
    font-family: var(--font-family);
    margin-right: 10px;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    line-height: 1.3;
}

.ServiceBloc p {
    color: white;
}

.arrowDown {
    width: 7px;
    height: 8px;
    transition: 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955),
        -webkit-transform 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.arrowUp {
    width: 7px;
    height: 8px;
    transform: rotate(180deg);
    transition: 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955),
        -webkit-transform 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.ServiceBlockLink {
    display: none;
}

.ServiceBlockLinkExpended {
    display: block;
    margin-top: -5px;
}

.footerLanguagesHanchor {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0;
    display: inline-block;
    height: 24px;
    width: 24px;
    min-width: 24px;
    border-radius: 50%;
    border: 0;
    margin-left: 10px;
}

.footerLanguagesHanchorRTL {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0;
    display: inline-block;
    height: 24px;
    width: 24px;
    min-width: 24px;
    border-radius: 50%;
    border: 0;
    margin-right: 10px;
}

.arrowTop {
    width: 12px;
    height: 12px;
}

.leftBlock {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    border-right: solid 1px rgba(255, 255, 255, 0.1);
    padding-top: 100px;
    padding-bottom: 70px;
}

.leftBlockRTL {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    border-left: solid 1px rgba(255, 255, 255, 0.1);
    padding-top: 70px;
    padding-bottom: 70px;
}

.rightBlock {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 70px;
    padding-bottom: 70px;
}

@media screen and (max-width: 600px) {
    .footerHeader {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        padding-top: 40px;
        border-bottom: solid 1px rgba(255, 255, 255, 0.1);
        overflow-x: hidden;
    }

    .footerContentRTL {
        width: 100%;
        background-color: #0f1a48;
        direction: rtl;
    }

    .footerContentLTR {
        width: 100%;
        background-color: #0f1a48;
        direction: ltr;
    }

    .newsletterBlockDescription {
        font-family: var(--font-family);
        color: white;
        font-size: 11px;
        letter-spacing: 1.4px;
        width: 300px;
        text-align: center;
    }

    .newsletterBlockTitle {
        font-family: 'Baskerville';
        color: #a48156;
        font-size: 17px;
        text-align: center;
    }
    .subscribeBlock {
        background-color: white;
        width: 184px;
        height: 54px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 2px;
    }

    .contactUsBlock {
        display: flex;
        align-items: center;
        border-right: solid 1px rgba(255, 255, 255, 0.1);
        flex-direction: column;
        width: 100%;
        border-top: solid 1px rgba(255, 255, 255, 0.1);
        padding-top: 47px;
    }

    .contactUsBlockIn {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }

    .findBoutiqueBlock {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        border-top: solid 1px rgba(255, 255, 255, 0.1);
        padding-top: 50px;
    }

    .findBoutiqueBlockIn {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }

    .leftBlock {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-bottom: 70px;
    }

    .leftBlockRTL {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        border-left: solid 1px rgba(255, 255, 255, 0.1);
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .rightBlock {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        padding-top: 10px;
        padding-bottom: 0px;
    }

    .contactUsBlockLogo {
        margin-right: 0px;
    }

    .contactUsBlockTitle {
        font-family: 'Baskerville';
        color: white;
        font-size: 17px;
        opacity: 0.5;
        text-align: center;
        line-height: 11px;
    }

    .contactUsBlockDescription {
        font-family: var(--font-family);
        color: white;
        font-size: 15px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        text-align: center;
        line-height: 11px;
        padding-bottom: 30px;
    }

    .footerLink {
        width: 75%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }

    .footerLinkItem {
        width: 50px;
        text-align: center;
    }

    .footerLanguagesHanchor {
        display: none;
    }

    .footerExternalLinkContainer {
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: #0e1737;
        padding-bottom: 22px;
    }

    .footerLanguages {
        margin-top: 20px;
        margin-right: 0px;
        display: flex;
        flex-direction: row;
    }
}

