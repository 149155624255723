.container {
    display: flex;
    flex-direction: column;
    width: 666px;
    margin-bottom: 20px;
}

.inputStyle {
    resize: none;
    height: 119px;
    border: 1px solid var(--stroke, #e3e4e7);
    color: var(--main-color);
    font-family: var(--font-family);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 1px;
}

@media screen and (max-width: 520px) {
    .container {
        display: flex;
        flex-direction: column;
        width: 90%;
        margin-bottom: 20px;
    }

    .inputStyle {
        resize: none;
        height: 119px;
        border: 1px solid var(--stroke, #e3e4e7);
        color: var(--main-color);
        font-family: var(--font-family);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 1px;
    }
}

