.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 666px;
}

.titleLabelContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 20px;
    height: 100px;
    border-bottom: 1px solid #e3e4e7;
    min-width: 666px;
}

.titleLabel {
    color: #0e1d51;
    font-family: var(--font-family);
    font-size: 20px;
    letter-spacing: 2px;
    font-style: normal;
    font-weight: normal;
    padding-bottom: 0px;
    margin-bottom: 5px;
}

.titleSubLabel {
    color: #0e1d51;
    font-family: var(--font-family);
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 16px;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 30px;
}

.titleLabelSectionContainer {
    display: flex;
    align-items: center;
    width: 666px;
    justify-content: space-between;
}

.titleLabelSectionContainerOptional {
    display: flex;
    align-items: flex-start;
    width: 666px;
    border-top: 1px solid var(--stroke, #e3e4e7);
    border-bottom: 1px solid var(--stroke, #e3e4e7);
    padding: 15px 0px;
    margin-bottom: 40px;
}

.titleLabelSectionOptional {
    color: #0e1d51;
    font-family: var(--font-family);
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 2px;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 15px;
    margin-left: 12px;
}

.separator {
    border-bottom: 1px solid var(--stroke, #e3e4e7);
    height: 1px;
    width: 100%;
    margin-top: 10px;
}

.editContainerLTR {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.editContainerRTL {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.iconEditBtnLTR {
    width: 11px;
    height: 13px;
}

.iconEditBtnRTL {
    width: 11px;
    height: 13px;
    margin-left: 10px;
}

.iconEditTitle {
    margin-left: 3px;
}

.titleEditIcon {
    color: #0e1d51;
    font-family: var(--font-family);
    font-size: 11px;
    letter-spacing: 2px;
    line-height: normal;
    font-style: normal;
    font-weight: normal;
    margin-left: 5px;
}

.checkmarkContainerLTR {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.checkmarkContainerRTL {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.iconCheckmarkLTR {
    width: 16px;
    height: 16px;
}

.iconCheckmarkRTL {
    width: 16px;
    height: 16px;
    margin-left: 10px;
}

@media screen and (max-width: 520px) {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-width: 100%;
    }

    .titleLabelContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 60px;
        margin-bottom: 20px;
        height: 100px;
        border-bottom: 1px solid #e3e4e7;
        min-width: 90%;
    }

    .titleLabelSectionContainer {
        display: flex;
        align-items: flex-start;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .titleLabelContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 60px;
        margin-bottom: 20px;
        height: 100px;
        border-bottom: 1px solid #e3e4e7;
        min-width: 90%;
    }

    .containerInfosDetails {
        width: 90%;
    }
}

