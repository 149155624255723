.headerContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 105px;
    background-color: #0c1b52;
    -webkit-box-shadow: 0 6px 4px -4px #d6d8e1;
    -moz-box-shadow: 0 6px 4px -4px #d6d8e1;
    box-shadow: 0 6px 4px -4px #d6d8e1;
}

.logoLTR {
    width: 100px;
}

.logoRTL {
    width: 100px;
}

.myProfileIcon {
    width: 22.1px;
    height: 25px;
    margin-right: 102px;
}

@media screen and (max-width: 520px) {
    .headerContent {
        display: none;
    }
}

