.headerContentLogged {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 105px;
    background-color: #0c1b52;
    -webkit-box-shadow: 0 6px 4px -4px #d6d8e1;
    -moz-box-shadow: 0 6px 4px -4px #d6d8e1;
    box-shadow: 0 6px 4px -4px #d6d8e1;
}

.logoContainerLTR {
    width: 33%;
}

.logoContainerRTL {
    width: 33%;
}

.logoLTR {
    width: 100px;
}

.logoRTL {
    width: 100px;
}

.arrowBackRightSpaceLTR {
    width: 33%;
}

.arrowBackRightSpaceRTL {
    width: 33%;
}

.ProfileIconContainerLTR {
    width: 33%;
}

.ProfileIconContainerRTL {
    width: 33%;
}

.myProfileIconLTR {
    width: 24px;
    height: 24px;
    padding-left: 40%;
}

.myProfileIconRTL {
    width: 24px;
    height: 24px;
    padding-right: 40%;
}

@media screen and (max-width: 520px) {
    .headerContentLogged {
        display: none;
    }
}

