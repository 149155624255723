.container {
    width: 666px;
    height: 54px;
    text-align: center;
    background: white;
    border: 1px solid #e3e4e7;
    margin-bottom: 35px;
    cursor: pointer;
}

.titleLabel {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 2px;
    color: var(--main-color);
    padding-top: 7px;
}

@media screen and (max-width: 520px) {
    .container {
        width: 90%;
        height: 54px;
        text-align: center;
        background: white;
        border: 1px solid #e3e4e7;
        margin-bottom: 35px;
        cursor: pointer;
    }
}

