.backgroundImageContainer {
    width: 100%;
    background-color: #e3e3e3;
}

.backgroundImage {
    width: 100%;
}

.backgroundImageLabelLTR {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 2px;
    color: #f7f7f7;
    margin-top: -50px;
    text-align: center;
}

.backgroundImageLabelRTL {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 2px;
    color: #f7f7f7;
    margin-top: -50px;
    text-align: center;
}

@media screen and (max-width: 520px) {
    .backgroundImage {
        object-fit: cover;
        height: 180px;
    }
    .backgroundImageLabelLTR {
        font-family: var(--font-family);
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        letter-spacing: 2px;
        color: #f7f7f7;
        margin-top: -50px;
        text-align: center;
        margin-left: 0px;
    }

    .backgroundImageLabelRTL {
        font-family: var(--font-family);
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        letter-spacing: 2px;
        color: #f7f7f7;
        margin-top: -50px;
        text-align: center;
        margin-right: 0px;
    }
}

