.container {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
}

.containerThankYouImage {
}

.containerThankYouLabel {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    letter-spacing: 1px;
    color: var(--main-color);
    height: 35px;
}

.thankYouLabelName {
    font-weight: bold;
}

.thanksYouIcon {
    width: 30px;
    height: 30px;
}

.containerThankYouLabelSubtitle {
    font-family: var(--font-family);
    color: var(--main-color);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1px;
}

