.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 25px;
    background-color: white;
    height: 223px;
    padding: 55px;
}

.title {
    color: #0e1d51;
    font-family: var(--font-family);
    letter-spacing: 2px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
}

.description {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    font-family: var(--font-family);
    text-align: center;
    letter-spacing: 1px;
    color: var(--main-color);
}

.calendarIcon {
    width: 47.4px;
    height: 45px;
    margin-bottom: 10px;
}

@media screen and (max-width: 600px) {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 25px;
        margin-bottom: 25px;
        text-align: center;
    }
}

