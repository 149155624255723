.container {
    width: 666px;
    margin-bottom: 100px;
}

.containerLabel {
    width: 100%;
    color: #62698c;
    font-family: var(--font-family);
    background: #ffffff;
}

.ListParentContainer {
    cursor: pointer;
    outline: 1px solid #e3e4e7;
    border: 0;
    padding-top: 17px;
    height: 51.83px;
}

.dropdownList {
    display: flex;
    flex-direction: column;
}

.dropdownListItemLTR {
    width: 100%;
    background: #ffffff;
    border: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    outline: 1px solid #e3e4e7;
    cursor: pointer;
}

.dropdownListItemRTL {
    width: 100%;
    background: #ffffff;
    border: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    outline: 1px solid #e3e4e7;
    cursor: pointer;
}

.dropdownListItemLTR a {
    text-decoration: none;
    color: var(--main-color);
    font-family: var(--font-family);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;
}

.dropdownListItemLTR p {
    text-decoration: none;
    color: var(--main-color);
    font-family: var(--font-family);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;
}

.dropdownListItemRTL a {
    text-decoration: none;
    color: var(--main-color);
    font-family: var(--font-family);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;
}

.dropdownListItemRTL p {
    text-decoration: none;
    color: var(--main-color);
    font-family: var(--font-family);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;
}

.titleContainer {
    color: #62698c;
    font-family: var(--font-family);
    font-size: 14px;
}

.rowContainerLTR {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: 42%;
}

.rowContainerRTL {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-right: 42%;
}

.titleStyle {
    color: var(--main-color);
    font-family: var(--font-family);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2px;
}

.iconTitleLTR {
    margin-left: 10px;
}

.iconTitleRTL {
    margin-right: 10px;
}

.iconImg {
    width: 30px;
    height: 22px;
}

@media screen and (max-width: 950px) and (min-width: 520px) {
    .container {
        display: flex;
        flex-direction: column;
        width: 666px;
        margin-bottom: 50px;
    }

    .containerLabel {
        width: 100%;
        color: #62698c;
        font-family: var(--font-family);
        background: #ffffff;
        font-size: 14px;
    }
}

@media screen and (max-width: 520px) {
    .container {
        display: flex;
        flex-direction: column;
        width: 250px;
        margin-bottom: 50px;
    }

    .containerLabel {
        width: 100%;
        color: #62698c;
        font-family: var(--font-family);
        background: #ffffff;
        font-size: 14px;
    }

    .rowContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-left: 30%;
    }
}

