.container {
    width: 100%;
    margin-bottom: 20px;
}

.containerLabelLTR {
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    color: #62698c;
    font-family: var(--font-family);
    background: #ffffff;
    border: 0;
    padding: 10px 15px;
    outline: 1px solid #e3e4e7;
    border-right: 12px solid transparent;
}

.containerLabelRTL {
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    color: #62698c;
    font-family: var(--font-family);
    background: #ffffff;
    border: 0;
    padding: 10px 15px;
    outline: 1px solid #e3e4e7;
    border-left: 12px solid transparent;
}

.container select:disabled {
    opacity: 0.5;
    pointer-events: none;
}

@media screen and (max-width: 950px) and (min-width: 520px) {
    .containerLabelLTR {
        box-sizing: border-box;
        width: 100%;
        height: 50px;
        color: #62698c;
        font-family: var(--font-family);
        background: #ffffff;
        font-size: 14px;
        border: 0;
        padding: 10px 15px;
        outline: 1px solid #e3e4e7;
        border-right: 12px solid transparent;
    }

    .containerLabelRTL {
        box-sizing: border-box;
        width: 100%;
        height: 50px;
        color: #62698c;
        font-family: var(--font-family);
        background: #ffffff;
        font-size: 14px;
        border: 0;
        padding: 10px 15px;
        outline: 1px solid #e3e4e7;
        border-left: 12px solid transparent;
    }
}

@media screen and (max-width: 520px) {
    .container {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 20px;
    }

    .containerLabelLTR {
        box-sizing: border-box;
        width: 100%;
        height: 50px;
        color: #62698c;
        font-family: var(--font-family);
        background: #ffffff;
        font-size: 14px;
        border: 0;
        padding: 10px 15px;
        outline: 1px solid #e3e4e7;
        border-right: 12px solid transparent;
    }

    .containerLabelRTL {
        box-sizing: border-box;
        width: 100%;
        height: 50px;
        color: #62698c;
        font-family: var(--font-family);
        background: #ffffff;
        font-size: 14px;
        border: 0;
        padding: 10px 15px;
        outline: 1px solid #e3e4e7;
        border-left: 12px solid transparent;
    }
}

