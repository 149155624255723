.containerLTR {
    width: 666px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.containerRTL {
    width: 666px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.containerRadioListLTR {
    display: flex;
    flex-direction: row;
}

.containerRadioListRTL {
    display: flex;
    flex-direction: row;
}

.containerRadioListHorizontalLTR {
    display: flex;
    flex-direction: row;
}

.containerRadioListHorizontalRTL {
    display: flex;
    flex-direction: row;
}

.containerRadioListItemHorizontalLTR:first-child {
    margin-top: 0px;
    margin-right: 20px;
}

.containerRadioListItemHorizontalRTL:first-child {
    margin-top: 0px;
    margin-left: 20px;
}

.containerRadioListItemLTR {
    margin-right: 50px;
    font-family: var(--font-family);
    color: #62698c;
    font-size: 12px;
}

.containerRadioListItemRTL {
    margin-left: 50px;
    font-family: var(--font-family);
    color: #62698c;
    font-size: 12px;
}

.labelOption {
    margin-left: 5px;
}

input[type='radio'] {
    accent-color: var(--main-color);
}

@media screen and (max-width: 950px) and (min-width: 520px) {
    .containerRadioListItemLTR {
        margin-right: 50px;
        font-family: var(--font-family);
        color: #62698c;
        font-size: 12px;
    }

    .containerRadioListItemRTL {
        margin-left: 50px;
        font-family: var(--font-family);
        color: #62698c;
        font-size: 12px;
    }

    .containerRadioListHorizontalLTR {
        margin-bottom: 10px;
        font-family: var(--font-family);
        color: #62698c;
        font-size: 14px;
    }

    .containerRadioListHorizontalRTL {
        margin-bottom: 10px;
        font-family: var(--font-family);
        color: #62698c;
        font-size: 14px;
    }
}

@media screen and (max-width: 520px) {
    .containerLTR {
        width: 90%;
        margin-bottom: 20px;
    }

    .containerRTL {
        width: 90%;
        margin-bottom: 20px;
    }

    .containerRadioListItemLTR {
        margin-bottom: 10px;
    }

    .containerRadioListItemRTL {
        margin-bottom: 10px;
    }

    .containerRadioListItemLTR:first-child {
        margin-right: 0px;
        margin-bottom: 10px;
        font-family: var(--font-family);
        color: #62698c;
        font-size: 14px;
    }

    .containerRadioListItemRTL:first-child {
        margin-left: 0px;
        margin-bottom: 10px;
        font-family: var(--font-family);
        color: #62698c;
        font-size: 14px;
    }

    .containerRadioListItemHorizontalLTR:first-child {
        margin-top: 0px;
        margin-right: 20px;
    }

    .containerRadioListItemHorizontalRTL:first-child {
        margin-top: 0px;
        margin-left: 20px;
    }

    .containerRadioListLTR {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .containerRadioListRTL {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .containerRadioListHorizontalRTL {
        margin-bottom: 30px;
        font-family: var(--font-family);
        color: #62698c;
        font-size: 14px;
    }

    .containerRadioListHorizontalLTR {
        margin-bottom: 30px;
        font-family: var(--font-family);
        color: #62698c;
        font-size: 14px;
    }

    .containerRadioListItemHorizontalLTR:first-child {
        display: flex;
        flex-direction: row;
    }

    .containerRadioListItemHorizontalRTL:first-child {
        display: flex;
        flex-direction: row;
    }
}

