.containerRTL {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f6f6f6;
    flex-grow: 1;
    direction: rtl;
}

.containerLTR {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f6f6f6;
    flex-grow: 1;
    direction: ltr;
}

.bodyContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.containerCancelLTR {
    background-color: white;
    width: 962px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.containerCancelRTL {
    background-color: white;
    width: 962px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

@media screen and (max-width: 520px) {
    .containerCancelLTR {
        background-color: white;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .containerCancelRTL {
        background-color: white;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

