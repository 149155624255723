.container {
    width: 70%;
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: white;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 950px) and (min-width: 520px) {
    .container {
        width: 90%;
        margin-top: 30px;
        margin-bottom: 30px;
        background-color: white;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 520px) {
    .container {
        width: 100%;
        margin-top: 0px;
        margin-bottom: 0px;
        background-color: white;
        justify-content: center;
        align-items: center;
    }
}

