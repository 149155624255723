.container {
    width: 100%;
}

.tabContainerLTR {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 54px;
    margin-bottom: 30px;
}

.tabContainerRTL {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 54px;
    margin-bottom: 30px;
}

.tabContainerMobileRTL {
    display: none;
}

.tabContainerMobileLTR {
    display: none;
}

.nextAppointmentContainerLTR {
    margin-right: 82px;
    z-index: 10;
}

.nextAppointmentContainerSelectedLTR {
    border-bottom: 1px solid #c2a27a;
    z-index: 10;
}

.nextAppointmentContainerRTL {
    margin-left: 82px;
    z-index: 10;
}

.nextAppointmentContainerSelectedRTL {
    margin-left: 82px;
    border-bottom: 1px solid #c2a27a;
    z-index: 10;
}

.pastAppointmentContainerLTR {
    margin-left: 82px;
    z-index: 10;
}

.pastAppointmentContainerSelectedLTR {
    border-bottom: 1px solid #c2a27a;
    z-index: 10;
}

.pastAppointmentContainerRTL {
    z-index: 10;
}

.pastAppointmentContainerSelectedRTL {
    border-bottom: 1px solid #c2a27a;
    z-index: 10;
}

.nextAppointmentLabel {
    color: #0e1d51;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 2px;
    cursor: pointer;
}

.nextAppointmentLabelSelected {
    color: #c2a27a;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 2px;
    cursor: pointer;
}

.pastAppointmentContainer {
}

.pastAppointmentContainerSelected {
    border-bottom: 1px solid #c2a27a;
}

.pastAppointmentLabel {
    color: #0e1d51;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 2px;
    cursor: pointer;
}

.pastAppointmentLabelSelected {
    color: #c2a27a;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 2px;
    cursor: pointer;
}

.listContainer {
    background-color: white;
    margin: 1.5rem 0;
    display: flex;
    justify-content: center;
}

.tableContainer {
    table-layout: auto;
    width: 90%;
    border-collapse: collapse;
}

.tableContainer tr {
    border-bottom: 1px solid #e3e4e7;
    width: 90%;
}

.tableContainer th {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 2px;
    color: var(--main-color);
    padding-top: 32px;
    padding-bottom: 20px;
}

.tableContainer td {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 2px;
    color: var(--main-color);
    padding-top: 16px;
    padding-bottom: 16px;
    height: 49px;
}

.cancelAppointmentContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.crossIconLTR {
    width: 16px;
    height: 16px;
    margin-right: 10px;
}

.crossIconLabel {
    border-bottom: 0.1px solid #747886;
    color: #747886;
}

.tableContainerMobile {
    display: none;
}

.refreshContainerLTR {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-right: 10px;
    margin-top: -66px;
    /* cursor: pointer; */
    /* z-index: 1; */
}

.refreshContainerRTL {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: -66px;
    /* cursor: pointer; */
    /* z-index: 1; */
}

.logoContainerLTR {
    margin-right: 10px;
    margin-top: 3px;
}

.logoContainerRTL {
    margin-left: 10px;
    margin-top: 3px;
}

.logo {
    width: 16px;
    height: 16px;
    cursor: pointer;
    z-index: 10;
}

.refreshListLabel {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 2px;
    text-decoration-line: underline;
    color: var(--main-color);
    cursor: pointer;
    z-index: 10;
}

@media screen and (max-width: 950px) and (min-width: 520px) {
    .refreshContainerLTR {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 90%;
        margin-left: 15px;
        margin-top: 15px;
        cursor: pointer;
        z-index: 1;
    }

    .refreshContainerRTL {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 90%;
        margin-top: 15px;
        cursor: pointer;
        z-index: 1;
    }
}

@media screen and (max-width: 520px) {
    .container {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .tabContainerLTR {
        display: none;
    }

    .tabContainerRTL {
        display: none;
    }

    .tabContainerMobileRTL {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin-top: 24px;
        overflow: scroll;
        width: 100%;
        padding-left: 0px;
    }

    .tabContainerMobileLTR {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin-top: 24px;
        padding-left: 0px;
        overflow-x: scroll;
    }

    .listContainer {
        background-color: white;
        width: 100%;
        margin: 1.5rem 0;
        display: flex;
        justify-content: center;
    }

    .tableContainer {
        display: none;
    }

    .tableContainerMobile {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .nextAppointmentContainerLTR {
        margin-right: 24px;
        flex: 0 0 auto;
    }

    .nextAppointmentContainerRTL {
        margin-left: 0px;
        margin-right: 24px;
        flex: 0 0 auto;
    }

    .nextAppointmentContainerSelectedLTR {
        margin-right: 24px;
        border-bottom: 1px solid #c2a27a;
        margin-bottom: 10px;
        flex: 0 0 auto;
        z-index: 10;
    }

    .nextAppointmentContainerSelectedRTL {
        margin-right: 24px;
        border-bottom: 1px solid #c2a27a;
        margin-bottom: 10px;
        flex: 0 0 auto;
        margin-left: 0px;
        z-index: 10;
    }

    .pastAppointmentContainerLTR {
        margin-right: 20px;
        flex: 0 0 auto;
        margin-left: 0px;
        z-index: 10;
    }

    .pastAppointmentContainerRTL {
        margin-right: 20px;
        flex: 0 0 auto;
        z-index: 10;
    }

    .pastAppointmentContainerSelectedLTR {
        border-bottom: 1px solid #c2a27a;
        flex: 0 0 auto;
        padding-right: 20px;
    }

    .pastAppointmentContainerSelectedRTL {
        border-bottom: 1px solid #c2a27a;
        flex: 0 0 auto;
        margin-right: 20px;
    }

    .refreshContainerLTR {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin-left: 20px;
        margin-top: 15px;
        cursor: pointer;
        z-index: 1;
        overflow-x: hidden;
    }

    .refreshContainerRTL {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        margin-left: 20px;
        margin-top: 15px;
        cursor: pointer;
        z-index: 1;
        overflow-x: hidden;
    }
}

