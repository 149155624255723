.container {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: white;
    justify-content: center;
    align-items: center;
}

.containerForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.titleLabelSectionContainer {
    display: flex;
    align-items: flex-start;
    width: 666px;
}

.titleLabelSectionContainerOptional {
    display: flex;
    align-items: flex-start;
    width: 666px;
    border-top: 1px solid var(--stroke, #e3e4e7);
    border-bottom: 1px solid var(--stroke, #e3e4e7);
    padding: 15px 0px;
    margin-bottom: 40px;
}

.titleLabelSection {
    color: #0e1d51;
    font-family: var(--font-family);
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 2px;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 15px;
}

.titleLabelSection {
    color: #0e1d51;
    font-family: var(--font-family);
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 2px;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 15px;
}

.titleLabelSectionOptional {
    color: #0e1d51;
    font-family: var(--font-family);
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 2px;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 15px;
}

.titleLabelContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 20px;
    height: 100px;
    border-bottom: 1px solid #e3e4e7;
    min-width: 666px;
}

.titleLabel {
    color: #0e1d51;
    font-family: var(--font-family);
    font-size: 20px;
    letter-spacing: 2px;
    font-style: normal;
    font-weight: normal;
    padding-bottom: 0px;
    margin-bottom: 5px;
}

.titleSubLabel {
    color: #0e1d51;
    font-family: var(--font-family);
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 16px;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 30px;
}

.dropDownParentContainer {
    position: relative;
    z-index: 1;
}

.dropDownContainerLTR {
    display: flex;
    flex-direction: row;
    width: 666px;
}

.dropDownContainerRTL {
    display: flex;
    flex-direction: row;
    width: 666px;
}

.dropDownContainerNotDivided {
    display: flex;
    flex-direction: row;
    width: 666px;
}

.dropDownContainerLTR > div:first-child {
    margin-right: 20px;
}

.dropDownContainerRTL > div:first-child {
    margin-left: 20px;
}

.dropDownContainerNotDivided > div:first-child {
    margin-right: 0px;
}

@media screen and (max-width: 950px) and (min-width: 520px) {
    .container {
        width: 100%;
        margin-top: 30px;
        margin-bottom: 30px;
        background-color: white;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 520px) {
    .container {
        width: 100%;
        margin-top: 30px;
        margin-bottom: 30px;
        background-color: white;
        justify-content: center;
        align-items: center;
    }

    .dropDownParentContainer {
        position: relative;
        z-index: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .dropDownContainerLTR {
        display: flex;
        flex-direction: column;
        width: 90%;
    }

    .dropDownContainerRTL {
        display: flex;
        flex-direction: column;
        width: 90%;
    }

    .dropDownContainerLTR > div:first-child {
        margin-right: 0px;
    }

    .dropDownContainerRTL > div:first-child {
        margin-left: 0px;
    }

    .titleLabelContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0px;
        margin-bottom: 20px;
        height: 100px;
        border-bottom: 1px solid #e3e4e7;
        min-width: 90%;
    }

    .titleLabelSectionContainer {
        display: flex;
        align-items: flex-start;
        width: 90%;
    }

    .dropDownContainerNotDivided {
        display: flex;
        flex-direction: row;
        width: 90%;
    }

    .titleLabelSectionContainerOptional {
        display: flex;
        align-items: flex-start;
        width: 90%;
        border-top: 1px solid var(--stroke, #e3e4e7);
        border-bottom: 1px solid var(--stroke, #e3e4e7);
        padding: 15px 0px;
        margin-bottom: 0px;
    }

    .buttonBlockContainer {
        width: 90%;
    }
}

