.container {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: white;
    justify-content: center;
    align-items: center;
}

.containerForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.titleInfos {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 1px;
    color: var(--main-color);
}

.titleSubInfos {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 1px;
    color: var(--main-color);
}

.titleLabelSectionInfos {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 1px;
    color: var(--main-color);
}

.titleLabelSectionInfosMandatory {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 1px;
    color: var(--main-color);
}

.titleLabelSection {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 1px;
    text-align: center;
    letter-spacing: 1px;
    color: var(--main-color);
}

.titleLabelSectionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 666px;
    margin-bottom: 30px;
}

.titleLabelSectionContainerInfos {
    display: flex;
    align-items: flex-start;
    width: 666px;
}

.lineSeparator {
    height: 2px;
    border-bottom: 1px solid #e3e4e7;
    margin-bottom: 20px;
    margin-top: 1px;
    width: 666px;
}

.titleLabelContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    margin-bottom: 20px;
    height: 100px;
    border-bottom: 1px solid #e3e4e7;
    min-width: 666px;
}

.titleLabel {
    color: #0e1d51;
    font-family: var(--font-family);
    font-size: 16px;
    letter-spacing: 2px;
    font-style: normal;
    font-weight: normal;
}

.dropDownParentContainer {
    /* position: relative; */
    /* z-index: 1; */
}

.dropDownContainerLTR {
    display: flex;
    flex-direction: row;
    width: 666px;
}

.dropDownContainerRTL {
    display: flex;
    flex-direction: row;
    width: 666px;
}

.dropDownContainerLTR > div:first-child {
    margin-right: 20px;
}

.dropDownContainerRTL > div:first-child {
    margin-left: 20px;
}

.customSectionLTR {
    display: flex;
    flex-direction: row;
    width: 666px;
}

.customSectionRTL {
    display: flex;
    flex-direction: row;
    width: 666px;
}

.customSectionLTR > div:first-child {
    margin-right: 20px;
}

.customSectionRTL > div:first-child {
    margin-left: 20px;
}

.section1 {
    width: 50%;
}

.section2LTR {
    width: 50%;
    display: flex;
    flex-direction: row;
}

.section2RTL {
    width: 50%;
    display: flex;
    flex-direction: row;
}

.subSection1LTR {
    width: 50%;
    margin-right: 10px;
}

.subSection1RTL {
    width: 50%;
    margin-left: 10px;
}

.subSection2 {
    width: 50%;
    margin-top: 12.7px;
}

.titleLabelSectionContainer {
    display: flex;
    align-items: flex-start;
    width: 666px;
}

.titleLabelSectionContainerOptional {
    display: flex;
    align-items: flex-start;
    width: 666px;
    border-top: 1px solid var(--stroke, #e3e4e7);
    border-bottom: 1px solid var(--stroke, #e3e4e7);
    padding: 15px 0px;
    margin-bottom: 40px;
}

.titleLabelSectionOptional {
    color: #0e1d51;
    font-family: var(--font-family);
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 2px;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 15px;
}

@media screen and (max-width: 950px) and (min-width: 520px) {
    .container {
        width: 100%;
        margin-top: 30px;
        margin-bottom: 30px;
        background-color: white;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 520px) {
    .container {
        width: 100%;
        margin-top: 30px;
        margin-bottom: 30px;
        background-color: white;
        justify-content: center;
        align-items: center;
    }

    .dropDownParentContainer {
        position: relative;
        z-index: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .dropDownContainer {
        display: flex;
        flex-direction: column;
        width: 90%;
    }

    .dropDownContainer > div:first-child {
        margin-right: 0px;
    }

    .titleLabelContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 60px;
        margin-bottom: 20px;
        height: 100px;
        border-bottom: 1px solid #e3e4e7;
        min-width: 90%;
    }

    .titleLabelSection {
        font-family: var(--font-family);
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 1px;
        color: var(--main-color);
    }

    .titleInfos {
        font-family: var(--font-family);
        font-style: normal;
        font-weight: bold;
        font-size: 17px;
        line-height: 23px;
        text-align: center;
        letter-spacing: 1px;
        color: var(--main-color);
    }

    .titleLabelSectionContainer {
        text-align: center;
        width: 90%;
    }

    .titleLabelSectionContainerInfos {
        display: flex;
        align-items: flex-start;
        width: 90%;
    }

    .lineSeparator {
        height: 0px;
        border-bottom: 0px solid #e3e4e7;
        margin-bottom: 0px;
        margin-top: 0px;
        width: 666px;
    }

    .dropDownContainerLTR {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .dropDownContainerRTL {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .dropDownContainerLTR > div:first-child {
        margin-right: 0px;
    }

    .dropDownContainerRTL > div:first-child {
        margin-left: 0px;
    }

    .customSectionLTR {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .customSectionRTL {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .customSectionLTR > div:first-child {
        margin-right: 0px;
    }

    .customSectionRTL > div:first-child {
        margin-left: 0px;
    }

    .section1 {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .section2LTR {
        width: 96.3%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-left: 5%;
    }

    .section2RTL {
        width: 96.3%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-right: 5%;
    }
}

