.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 25px;
    background-color: white;
    height: 116px;
    padding: 50px;
}

.description {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    font-family: var(--font-family);
    text-align: center;
    letter-spacing: 1px;
    color: var(--main-color);
}

.loaderIcon {
    width: 32px;
}

