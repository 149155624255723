.container {
    width: 90%;
    display: flex;
    flex-direction: column;
}

.buttonContainer {
    margin: auto;
}

@media screen and (max-width: 950px) and (min-width: 520px) {
    .container {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 520px) {
    .buttonContainer {
        margin: unset;
    }
}

