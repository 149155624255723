.container {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
}

.containerCancelHeaderImage {
}

.containerCancelHeaderLabel {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 32px;
    letter-spacing: 1px;
    color: var(--main-color);
}

.containerCancelHeaderName {
    font-weight: bold;
}

.cancelHeaderIcon {
    width: 50px;
    height: 50px;
}

