.container {
    width: 100%;
}

.tabContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 54px;
}

.nextAppointmentContainer {
    margin-right: 82px;
}

.nextAppointmentContainerSelected {
    margin-right: 82px;
    border-bottom: 1px solid #c2a27a;
}

.nextAppointmentLabel {
    color: #0e1d51;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 2px;
    cursor: pointer;
}

.nextAppointmentLabelSelected {
    color: #c2a27a;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 2px;
    cursor: pointer;
}

.pastAppointmentContainer {
}

.pastAppointmentContainerSelected {
    border-bottom: 1px solid #c2a27a;
}

.pastAppointmentLabel {
    color: #0e1d51;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 2px;
    cursor: pointer;
}

.pastAppointmentLabelSelected {
    color: #c2a27a;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 2px;
    cursor: pointer;
}

.showNoShowLabel {
    color: #0e1d51;
}

.showNoShowLabelRed {
    color: #747886 !important;
}

.listContainer {
    background-color: white;
    width: 90%;
    margin: 1.5rem 0;
    display: flex;
    justify-content: center;
}

.tableContainer {
    table-layout: auto;
    width: 90%;
    border-collapse: collapse;
}

.tableContainer tr {
    border-bottom: 1px solid #e3e4e7;
    width: 90%;
}

.tableContainer th {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 2px;
    color: var(--main-color);
    padding-top: 32px;
    padding-bottom: 20px;
}

.tableContainer td {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 2px;
    color: var(--main-color);
    padding-top: 16px;
    padding-bottom: 16px;
    height: 49px;
}

.locationLabel {
    text-transform: uppercase;
}

.cancelAppointmentContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.crossIconLTR {
    width: 16px;
    height: 16px;
    margin-right: 10px;
}

.crossIconRTL {
    width: 16px;
    height: 16px;
    margin-left: 10px;
}

.crossIconLabel {
    border-bottom: 0.1px solid #747886;
    color: #747886;
}

.timeZoneLabel {
    font-size: 10px;
}

