.headerContent {
    display: none;
}

@media screen and (max-width: 520px) {
    .headerContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 96px;
        background-color: #0c1b52;
        -webkit-box-shadow: 0 6px 4px -4px #d6d8e1;
        -moz-box-shadow: 0 6px 4px -4px #d6d8e1;
        box-shadow: 0 6px 4px -4px #d6d8e1;
    }

    .headerArrowBackContainerRTL {
        display: none;
    }

    .headerArrowBackContainerLTR {
        display: none;
    }

    .logoContainerLTR {
    }

    .logoContainerRTL {
    }

    .logoLTR {
        width: 100px;
    }

    .logoRTL {
        width: 100px;
    }

    .myProfileIconMobile {
        width: 15.9px;
        height: 18px;
    }

    .arrowBackRightSpaceRTL {
        margin-right: auto;
        margin-top: -35px;
        padding-left: 30px;
    }

    .arrowBackRightSpaceLTR {
        margin-left: auto;
        margin-top: -35px;
        padding-right: 30px;
    }
}

