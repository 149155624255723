.container {
    width: 100%;
    margin-bottom: 20px;
}

.selectControl {
    width: '100%';
}

.selectMenu {
    width: '200%';
}

@media screen and (max-width: 950px) and (min-width: 520px) {
}

@media screen and (max-width: 520px) {
    .container {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 20px;
    }
}

