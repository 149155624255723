.headerContainer {
    border-bottom: 1px solid #e3e4e7;
    width: 504px;
    margin: auto;
}

.headerContainer p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 2px;
    color: var(--main-color);
}

.bodyContainer {
    width: 666px;
    /* margin-bottom: 65px;
    margin-left: 50px;
    margin-right: 40px; */
}

.bodyContainerLabelInfos {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 10px;
    text-align: center;
    letter-spacing: 1px;
    color: var(--main-color);
}

.bodyContainerLabel {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    text-align: left;
    letter-spacing: 1px;
    color: #92683c;
    text-align: justify;
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.buttonTopStyle {
    width: 335px;
    height: 54px;
    background-color: #747886;
    margin-bottom: 35px;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
}

.buttonTopStyleLabel {
    text-align: center;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 2px;
    color: #ffffff;
}

.buttonBottomStyle {
    width: 335px;
    height: 54px;
    border: 1px solid #e3e4e7;
    margin-bottom: 105px;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
}

.buttonBottomStyleLabel {
    text-align: center;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 2px;
    color: var(--main-color);
}

.containerLTR {
    /* width: 666px; */
    /* display: flex;
    justify-content: flex-end;
    margin-right: 27px;
    margin-top: 30px; */
}

.containerRTL {
    /* width: 666px; */
    /* display: flex;
    justify-content: flex-start;
    margin-left: 27px;
    margin-top: 30px; */
}

.crossIconRTL {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.crossIconLTR {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

@media screen and (max-width: 520px) {
    .headerContainer {
        border-bottom: 1px solid #e3e4e7;
        width: 100%;
        margin: auto;
        margin-top: 70px;
    }

    .bodyContainerLabel {
        font-family: var(--font-family);
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: unset;
        text-align: center;
        letter-spacing: 1px;
        color: var(--main-color);
    }

    .bodyContainer {
        margin-bottom: 35px;
        margin-left: 10px;
        margin-right: 10px;
    }
}

