.containerRTL {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f6f6f6;
    flex-grow: 1;
    direction: rtl;
}

.containerLTR {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f6f6f6;
    flex-grow: 1;
    direction: ltr;
}

.bodyContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.containerGuestLTR {
    background-color: white;
    width: 962px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.containerGuestRTL {
    background-color: white;
    width: 962px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.separator {
    border-bottom: 1px solid var(--stroke, #e3e4e7);
    height: 1px;
    width: 666px;
    margin-top: 10px;
}

.privacyContainer {
    width: 666px;
    margin-top: 25px;
}

.privacyLabelLTR {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1px;
    color: var(--main-color);
    text-align: left;
}

.privacyLabelRTL {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1px;
    color: var(--main-color);
    text-align: right;
}

@media screen and (max-width: 520px) {
    .containerGuestLTR {
        background-color: white;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .containerGuestRTL {
        background-color: white;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .privacyContainer {
        width: 90%;
        margin-top: 25px;
        margin-bottom: 20px;
    }

    .separator {
        border-bottom: 1px solid var(--stroke, #e3e4e7);
        height: 1px;
        width: 90%;
        margin-top: 10px;
    }

    .buttonBlockContainer {
        width: 90%;
    }

    .privacyLabelLTR {
        font-family: var(--font-family);
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 1px;
        color: var(--main-color);
        text-align: left;
    }

    .privacyLabelRTL {
        font-family: var(--font-family);
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 1px;
        color: var(--main-color);
        text-align: right;
    }
}

