.container {
    width: 666px;
    display: flex;
    flex-direction: column;
}

.containerInfosLTR {
    display: flex;
    flex-direction: column;
}

.containerInfosRTL {
    display: flex;
    flex-direction: column;
}

.containerInfosLabel {
    color: #0e1d51;
    font-family: var(--font-family);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 1px;
    text-align: start;
}

@media screen and (max-width: 520px) {
    .container {
        width: 90%;
        display: flex;
        flex-direction: column;
    }

    .containerInfos {
        display: flex;
        flex-direction: column;
    }
}

