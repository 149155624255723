@font-face {
    font-family: 'HelveticaNeue';
    src: local('HelveticaNeue'),
        url('./styles/fonts/HelveticaNeue.otf') format('opentype');
}

@font-face {
    font-family: 'HelveticaNeueMedium';
    src: local('HelveticaNeueMedium'),
        url('./styles/fonts/HelveticaNeueMedium.otf') format('opentype');
}

